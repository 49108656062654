import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {initUserAllowedProductIdList, initUserAllowedProductIdListSuccess, initUserAllowedProductIdListFailure} from "@store/user-infos/user-infos.action";
import {catchError, map, of, switchMap} from "rxjs";
import {UserService} from "@service/user.service";

@Injectable()
export class UserInfosEffect {
  constructor(public actions$: Actions, public userService: UserService) {
  }

  initUserAllowedProductIdList$ = createEffect(() => this.actions$.pipe(
    ofType(initUserAllowedProductIdList),
    switchMap(({authInfos}) => this.userService.getAllowedProductIdList(authInfos.mat).pipe(
      map(response => initUserAllowedProductIdListSuccess({data: response.allowedProductIdList})),
      catchError((error) => of(initUserAllowedProductIdListFailure({error: error})))
    ))
  ))
}
