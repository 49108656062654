import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {environment} from "@env/environment";
import {FiltersDto} from "@dto/filters.dto";
import {NotificationListDto} from "@dto/notification-list.dto";
import {NotificationDetailDto} from "@dto/notification-detail.dto";
import {AuthHttpClientService} from "@service/auth-http-client.service";
import {UserAllowedProductIdList} from "@dto/user-allowed-product-id-list";

@Injectable({
  providedIn: 'any',
})
export class NotificationService {
  private readonly url: string = environment.API_URL;

  constructor(private http: AuthHttpClientService) {
  }

  getNotificationList(currentPageNumber: number, filters: FiltersDto, pageSize: number): Observable<NotificationListDto> {
    return this.http.post<NotificationListDto>(`${this.url}/notification/list?page=${currentPageNumber}&size=${pageSize}`, filters);
  }

  getNotificationDetail(sourceId: string, userAllowedProductId: UserAllowedProductIdList): Observable<NotificationDetailDto> {
    return this.http.post<NotificationDetailDto>(`${this.url}/notification/detail/${sourceId}`, userAllowedProductId);
  }
}
