import {createReducer, on} from "@ngrx/store";
import {
  initUserAllowedProductIdList,
  initUserAllowedProductIdListFailure,
  initUserAllowedProductIdListSuccess
} from "@store/user-infos/user-infos.action";
import {NullableType} from "@type/nullable.type";
import {AuthStorageDto} from "@dto/auth-storage.dto";

export interface UserInfosState {
  authInfos: NullableType<AuthStorageDto>,
  allowedProductIdList: {data: string[], isLoading: boolean, error: NullableType<string>};
}

export const initialState: UserInfosState = {
  authInfos: null,
  allowedProductIdList: {data: [], isLoading: false, error: null}
};

export const userInfosReducer = createReducer(
  initialState,
  on(initUserAllowedProductIdList, (state, {authInfos}) => (
    {
      ...initialState,
      authInfos,
      allowedProductIdList: {...initialState.allowedProductIdList, isLoading: true}
    })
  ),

  on(initUserAllowedProductIdListSuccess, (state, {data}) => (
    {
      ...state,
      allowedProductIdList: {...state.allowedProductIdList, data, isLoading: false}
    })
  ),

  on(initUserAllowedProductIdListFailure, (state, {error}) => (
    {
      ...state,
      allowedProductIdList: {...state.allowedProductIdList, error, isLoading: false}
    })
  ),
);
