<ul class="button-menu">
  <li routerLinkActive="active" class="list-menu">
    <a routerLink="/dashboard" >
      <img class="icon" src="/assets/dashboard-logo.svg" alt="nof-icon">
      <span>Tableau de bord</span>
    </a>
  </li>
  <li routerLinkActive="active" class="list-menu">
    <a routerLink="/notifications">
      <img class="icon" src="/assets/notification-logo.png" alt="nof-icon">
      <span>Notifications</span>
    </a>
  </li>
</ul>
