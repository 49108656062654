import {createAction, props} from "@ngrx/store";
import {AuthStorageDto} from "@dto/auth-storage.dto";

const state = "[User Infos]";

export const initUserAllowedProductIdList = createAction(
  `${state} Init user allowed product id list`,
  props<{ authInfos: AuthStorageDto }>()
)

export const initUserAllowedProductIdListSuccess = createAction(
  `${state} Init user allowed product id list success`,
  props<{ data: string[] }>()
)

export const initUserAllowedProductIdListFailure = createAction(
  `${state} Init user allowed product id list failure`,
  props<{ error: string }>()
)
