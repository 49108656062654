import {Component} from '@angular/core';
import {RouterLink, RouterLinkActive} from "@angular/router";

@Component({
  selector: 'tmn-header-bar-button',
  standalone: true,
  templateUrl: './header-button.component.html',
  styleUrl: './header-button.component.css',
  imports: [
    RouterLink,
    RouterLinkActive
  ]
})
export class HeaderbarButtonComponent {
}
