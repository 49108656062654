import { Config, defaultEnvVariables } from "@env/default";

export const environment: Config = {
  ...defaultEnvVariables,
  production: false,
  API_URL: "https://apiv2-nprd.cloud.bpifrance.fr/dev/nof/trackmanotif/api",
  WS_URL: "https://nof.internal.dev.eks-nprd.cloud.bpifrance.fr/tmn",
  API_KEY: "dev_nof_29c48e8749fdb4b86d8eb833bc56df3a26f1f7bf",
  SSO: {
    ...defaultEnvVariables.SSO,
    issuer: "https://authmfamoa.web.bpifrance.fr",
    clientId: "HmtHAV8Ssv7fTT5ViY2O",
    discoveryDocumentEndpoint:
      "mga/sps/oauth/oauth20/metadata/OIDCP_All-Prof_MFA",
    enabled: false,
  },
};
